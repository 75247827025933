import { IAnswerType } from 'services/surveys/interfaces';

export const getAppearanceOptions = (type: IAnswerType) => {
	switch (type) {
		case 'text':
			return [
				{
					value: 'single',
					display: 'Single line'
				},
				{
					value: 'multiline',
					display: 'Multi line'
				}
			];
		case 'date':
			return [
				{
					value: 'month-year',
					display: 'Month-year'
				},
				{
					value: 'year',
					display: 'Year'
				},
				{
					value: 'no-calendar',
					display: 'No calendar'
				}
			];
		case 'select_one':
			return [
				{
					value: 'default',
					display: 'Default'
				},
				{
					value: 'compact',
					display: 'Compact'
				},
				{
					value: 'minimal',
					display: 'Minimal'
				},
				{
					value: 'likert',
					display: 'Likert scale'
				},
				{
					value: 'autocomplete',
					display: 'Autocomplete'
				},
				{
					value: 'horizontal',
					display: 'Horizontal'
				}
			];
		case 'select_multiple':
			return [
				{
					value: 'default',
					display: 'Default'
				},
				{
					value: 'compact',
					display: 'Compact'
				},
				{
					value: 'minimal',
					display: 'Minimal'
				},
				{
					value: 'horizontal',
					display: 'Horizontal'
				}
			];
			case 'property':
				return [
					{
						value: 'default',
						display: 'Default'
					},
					{
						value: 'compact',
						display: 'Compact'
					},
					{
						value: 'minimal',
						display: 'Minimal'
					},
					{
						value: 'horizontal',
						display: 'Horizontal'
					}
				];
		case 'image':
			return [
				{
					value: 'image_default',
					display: 'Default'
				},
				{
					value: 'annotate',
					display: 'Annotate'
				},
				{
					value: 'signature',
					display: 'Signature'
				}
			];
		default:
			return [
				{
					value: 'default',
					display: 'Default'
				}
			];
	}
};

export const appearanceDescriptions = {
	'' : 'Appears as a single line of text',
	single: 'Appears as a single line of text',
	multiline:
		'Best if used with web clients, makes the text box multiple lines long.',
	'month-year': 'Select a month and year only for the date.',
	year: 'Select only a year for the date.',
	'no-calendar': 'For mobile devices only, used to suppress the calendar.',
	compact:
		'Displays answer choices side by side with minimal padding and without radio buttons or checkboxes. Particularly useful with image choices.',
	minimal: 'Answer choices appear in a pull-down menu.',
	likert:
		'Best if used with web clients, makes the answer choices appear as a Likert scale.',
	default: 'Answer choices appear as list with radio buttons or checkboxes.',
	autocomplete: '',
	horizontal: 'Displays the answer choices horizontally, but in columns.',

	image_default: 'Allows the user to submit image.',
	annotate: 'Allows the user to draw on the image before submitting it.',
	signature: 'Collects a signature from the user.'
};

export const answerTypes = [
	{
		value: 'text',
		display: 'Text'
	},
	{
		value: 'select_one',
		display: 'Select one'
	},
	{
		value: 'select_multiple',
		display: 'Select multiple'
	},
	{
		value: 'emoji_rating',
		display: 'Emoji rating'
	},
	{
		value: 'integer',
		display: 'Integer'
	},
	{
		value: 'decimal',
		display: 'Decimal'
	},
	{
		value: 'geopoint',
		display: 'Geopoint'
	},
	{
		value: 'date',
		display: 'Date'
	},
	{
		value: 'range',
		display: 'Range'
	},
	{
		value: 'image',
		display: 'Image'
	},
	{
		value: 'email',
		display: 'Email'
	},
	{
		value: 'country',
		display: 'Country'
	},
	{
		value: 'age',
		display: 'Age'
	},
	{
		value: 'sex',
		display: 'Sex'
	},
	{
		value: 'nps',
		display: 'Net promoter score (NPS)'
	},
	{
		value: 'rating',
		display: 'Rating'
	},
	{
		value: 'property',
		display: 'Property'
	},
	{
		value: 'matrix_rating',
		display: 'Matrix rating'
	},
	{
		value: 'select_one_img',
		display: 'Select one image'
	},
	{
		value: 'select_multiple_img',
		display: 'Select multiple images'
	}
];

export const stringAnswerTypes = [
	{
		value: 'text',
		display: 'Text'
	},
	{
		value: 'select_one',
		display: 'Select one'
	},
	{
		value: 'select_multiple',
		display: 'Select multiple'
	},
	{
		value: 'emoji_rating',
		display: 'Emoji rating'
	},
	{
		value: 'email',
		display: 'Email'
	},
	{
		value: 'sex',
		display: 'Sex'
	},
	{
		value: 'nps',
		display: 'Net promoter score (NPS)'
	},
	{
		value: 'rating',
		display: 'Rating'
	},
	{
		value: 'property',
		display: 'Property'
	},
	{
		value: 'matrix_rating',
		display: 'Matrix rating'
	},
	{
		value: 'select_one_img',
		display: 'Select one image'
	},
	{
		value: 'select_multiple_img',
		display: 'Select multiple images'
	}
];

export const integerAnswerTypes = [
	{
		value: 'integer',
		display: 'Integer'
	},
	{
		value: 'decimal',
		display: 'Decimal'
	},
	{
		value: 'range',
		display: 'Range'
	},
	{
		value: 'age',
		display: 'Age'
	}
];

export const geopointAnswerType = [
	{
		value: 'geopoint',
		display: 'Geopoint'
	}
];

export const dateAnswerType = [
	{
		value: 'date',
		display: 'Date'
	}
];

export const imageAnswerType = [
	{
		value: 'image',
		display: 'Image'
	}
];

export const countryAnswerType = [
	{
		value: 'country',
		display: 'Country'
	}
];

export const redirectConditions = [
	{
		value: 0,
		display: '2 or higher'
	},
	{
		value: 1,
		display: '3 or higher'
	},
	{
		value: 2,
		display: '4 or higher'
	},
	{
		value: 3,
		display: '5'
	},
];

