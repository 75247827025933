import {
	AnalyticsType,
	ComparismentOperator,
	IQuestion,
	ISkipLogic,
	ISurvey,
	ISurveySection,
	ISurveyUI,
	LogicalOperator
} from 'services/surveys/interfaces';
import { extractNumberFromString, stripSpecialCharacters } from 'utils';
import { createUniqueQuestionIndex } from '.';

// pre process survey

export const relevantToSkipLogic = (questions: IQuestion[]): IQuestion[] => {
	
	return questions.map(question => {
		const skip_logic_operator: LogicalOperator =
			question.relevant?.indexOf(' and ') !== -1 ? 'and' : 'or';
		const skip_logic = question.relevant
			?.split(` ${skip_logic_operator} `)
			?.map(part => {
				if(part.indexOf('selected') !== -1){
					const q = part.substring(part.indexOf('(')+1, part.indexOf(','));
					const a = part.substring(part.indexOf(',')+2, part.indexOf(')'));
					return {
						question: stripSpecialCharacters(q),
						operator: "selected" as ComparismentOperator,
						answer: a.replaceAll('"', '')
					};
				}else{
					const simple = part.split(/(!=""|="")/);
					const matched = part.split(/(<=|>=|!=|=|>|<)/);
					if (simple.length > 1) {
						return {
							question: stripSpecialCharacters(simple[0]),
							operator: simple[1] as ComparismentOperator,
							answer: ''
						};
					  } else {
						return {
							question: stripSpecialCharacters(matched[0]),
							operator: matched[1] as ComparismentOperator,
							answer: matched[2]?.replaceAll('"', '')
						};
					}
				}
				
			});

		return {
			...question,
			relevant: '',
			skip_logic: [
				...(question.skip_logic ? question.skip_logic : []),
				...(skip_logic ? skip_logic : [])
			],
			skip_logic_operator
		};
	});
};

const preProcessParameters = (questions: IQuestion[]): IQuestion[] => {
	return questions.map(q => {
		if (q.parameters) {
			const params = q.parameters.split(';');
			return {
				...q,
				range_min: extractNumberFromString(params[0]),
				range_step: extractNumberFromString(params[2]),
				range_max: extractNumberFromString(params[1])
			};
		}

		return { ...q, range_min: '0', range_step: '1', range_max: '100' };
	});
};

const revertAppearanceValue = (questions: IQuestion[]): IQuestion[] => {
	return questions.map(q => ({
		...q,
		appearance:
			q.appearance === 'single' && q.type !== 'text' ? 'default' : q.appearance
	}));
};

const revertAnalyticsTypeValue = (questions: IQuestion[]): IQuestion[] => {
	return questions.map(q => ({
		...q,
		analytics_type: q.analytics_type === null ? false : true
	}));
};

export const preProcessSurvey = (survey: ISurvey): ISurveyUI => {
	let sectionFound = false;
	let questions = relevantToSkipLogic(survey.questions);
	questions = preProcessParameters(questions);
	questions = revertAppearanceValue(questions);
	questions = revertAnalyticsTypeValue(questions);
	// todo: move code below to separate reformat function

	const sections: ISurveySection[] = [];
	const general_questions: IQuestion[] = [];

	questions
		.filter(q => q.type !== 'end_group')
		.forEach(question => {
			if (question.type === 'begin_group') {
				sectionFound = true;
				sections.push({
					...question,
					questions: []
				});
			} else if (!sectionFound) {
				general_questions.push(question);
			} else {
				sections[sections.length - 1].questions.push(question);
			}
		});

	const surveyClone: any = { ...survey };
	delete surveyClone.questions;

	return {
		...surveyClone,
		general_questions,
		sections,
		tags: surveyClone.tags.map((tag: any) => {
			return {
				value: tag.tag_id,
				label: tag.name
			};
		})
	};
};

// process questions from templates

const renameImportedQuestionNames = (
	questions: IQuestion[],
	survey: ISurveyUI
) => {
	const nameReplacments: { [key: string]: string } = {};
	questions.forEach((question: any, i: number) => {
		const newName = `q_${createUniqueQuestionIndex(survey) + i}`;
		nameReplacments[question.name] = newName;
		question.name = newName;
	});
	questions.forEach((question: any) => {
		if (question.skip_logic.length) {
			question.skip_logic.forEach((skipLogicRule: ISkipLogic) => {
				skipLogicRule.question = nameReplacments[skipLogicRule.question];
			});
		}
	});

	return questions;
};

const deleteUnedededQuestionKeys = (questions: IQuestion[]): IQuestion[] => {
	questions.forEach((question: any) => {
		delete question.question_id;
		delete question.survey_id;
		delete question.list_id;
		delete question.question_position;
		delete question.choice_filter;
		delete question.parameters;
		delete question.constraint_message;
		delete question.constraint;
		delete question.calculation;
		delete question.path;
	});

	return questions;
};

export const deleteSkipLogic = (questions: IQuestion[]): IQuestion[] => {
	const questionNames = questions.map(question => question.name);

	questions.forEach(question => {
		if (
			question.skip_logic.some(
				skipLogicRule => !questionNames.includes(skipLogicRule.question)
			)
		) {
			question.skip_logic = [];
		}
	});

	return questions;
};

export const prepareQuestions = (
	questions: IQuestion[],
	survey: ISurveyUI
): IQuestion[] => {
	let newQuestions = relevantToSkipLogic(questions);
	newQuestions = deleteSkipLogic(newQuestions);
	newQuestions = preProcessParameters(newQuestions);
	newQuestions = revertAppearanceValue(newQuestions);
	newQuestions = deleteUnedededQuestionKeys(newQuestions);
	newQuestions = renameImportedQuestionNames(newQuestions, survey);

	return newQuestions;
};


export const fillPropertyTypeChoices = (questions: IQuestion[], availableLanguages: string[]) => {
	questions.forEach(question => {
	  if (question.type === 'property') {
		question.choices.forEach((choice: any) => {
		  // Get the labels that are already set
		  const existingLabels = Object.keys(choice.label);
		  // Iterate over the available languages
		  availableLanguages.forEach((language) => {
			// If a label in a certain language is missing, add it
			if (!existingLabels.includes(language)) {
			  // Use the label in any language that is already set
			  choice.label[language] = choice.label[existingLabels[0]];
			}
		  });
		});
	  }
	});
  }

// post process survey

const skipLogicToRelevant = (questions: IQuestion[]): IQuestion[] => {
	const withSkipLogic = questions.map(question => ({
		...question,
		relevant:
			question.skip_logic.length === 0
				? null
				: question.skip_logic
						?.map(
							condition => {
								if(condition.operator==='selected'){
									return `${condition.operator}(\${${condition.question}}, "${condition.answer}")`;
								}else{
									return `\${${condition.question}}${condition.operator}${
										condition.answer ? `"${condition.answer}"` : ''}`
								}


							}

								
						)
						.join(` ${question.skip_logic_operator} `)
	}));
	withSkipLogic.forEach(question => {
		delete (question as any).skip_logic;
		delete (question as any).skip_logic_operator;
	});
	
	return withSkipLogic;
};

const setDefaultQuestionValues = (questions: IQuestion[]): IQuestion[] => {
	return questions.map(question => ({
		question_id: null,
		...question
	}));
};

const setQuestionPositions = (questions: IQuestion[]): IQuestion[] => {
	return questions.map((question, index) => ({
		...question,
		question_position: index + 1
	}));
};

const setParameters = (questions: IQuestion[]): IQuestion[] => {
	return questions.map(q => {
		const withParameters =
			q.type === 'range'
				? {
						...q,
						parameters: `start=${q.range_min};end=${q.range_max};step=${q.range_step}`
				  }
				: q;
		delete withParameters.range_min;
		delete withParameters.range_step;
		delete withParameters.range_max;

		return withParameters;
	});
};

const postProcessAnalyticsType = (
	question: IQuestion
): AnalyticsType | null => {
	const questionType = question.type;
	const analyticsType = question.analytics_type;
	switch (true) {
		case questionType === 'select_one' && analyticsType === true:
			return 'select_one';
		case questionType === 'age' && analyticsType === true:
			return 'age';
		case questionType === 'sex' && analyticsType === true:
			return 'sex';
		case questionType === 'nps' && analyticsType === true:
			return 'nps';
		case questionType === 'country' && analyticsType === true:
			return 'location';
		case questionType === 'emoji_rating' && analyticsType === true:
			return 'rating';
		case questionType === 'rating' && analyticsType === true:
			return 'rating';
		case questionType === 'text' && analyticsType === true:
			return 'comment';
		case questionType === 'matrix_rating' && analyticsType === true:
				return 'rating';
		default:
			return null;
	}
};

const reformatAnalyticsType = (questions: IQuestion[]): IQuestion[] => {
	return questions.map(q => {
		const reformatedAnalytics = {
			...q,
			analytics_type: postProcessAnalyticsType(q)
		};

		return reformatedAnalytics;
	});
};

const reformatSurvey = (survey: ISurveyUI): ISurvey => {
	const questions: IQuestion[] = [
		...survey.general_questions.map(q => ({
			...q,
			parent: null
		}))
	];
	survey.sections.forEach(section => {
		const sectionQuestions = section.questions.map(q => ({
			...q,
			parent: section.name
		}));
		const groupStart: any = Object.assign({}, section);
		delete groupStart.questions;
		questions.push(groupStart);
		questions.push(...sectionQuestions);
		if (groupStart.question_id === undefined) {
			const groupEnd: any = Object.assign({}, section);
			groupEnd.type = groupEnd.type.replace('begin', 'end');
			groupEnd.name = groupEnd.name.replace('gb', 'ge');
			delete groupEnd.questions;
			questions.push(groupEnd);
		}
	});
	return {
		...survey,
		questions
	};
};

const renameAppearanceValue = (questions: IQuestion[]): IQuestion[] => {
	return questions.map(q => ({
		...q,
		appearance:
			q.appearance === 'default' || q.appearance === 'image_default'
				? ''
				: q.appearance
	}));
};

const removeUnneccesaryChoices = (questions: IQuestion[]): IQuestion[] => {
	const removedChoices = questions.map((question: IQuestion) => {
		return {
			...question,
			choices:
				question.type === 'select_one' ||
				question.type === 'select_multiple' ||
				question.type === 'sex' ||
				question.type === 'property' ||
				question.type === 'matrix_rating' ||
				question.type === 'select_one_img' ||
				question.type === 'select_multiple_img'
					? question.choices
					: []
		};
	});

	return removedChoices;
};

const coverMultiDescriptions = (
	values: ISurveyUI,
	availableLanguages: string[]
): Record<string, unknown> => {
	const entries = availableLanguages.map(lang => {
		return [
			lang,
			values.survey_description?.[lang] ? values.survey_description?.[lang] : ''
		];
	});

	return Object.fromEntries(entries);
};

const coverMultiTitels = (
	values: ISurveyUI,
	availableLanguages: string[]
): Record<string, unknown> => {
	const entries = availableLanguages.map(lang => {
		return [
			lang,
			values.survey_title?.[lang] ? values.survey_title?.[lang] : ''
		];
	});

	return Object.fromEntries(entries);
};

const coverMultiHints = (
	questions: IQuestion[],
	availableLanguages: string[]
): IQuestion[] => {
	const coveredMultiHints = questions.map(question => {
		return {
			...question,
			hint: Object.fromEntries(
				availableLanguages.map(lang => {
					return [lang, question.hint?.[lang] ? question.hint?.[lang] : ''];
				})
			)
		};
	});

	return coveredMultiHints;
};

export const postProcessSurvey = (
	values: ISurveyUI,
	availableLanguages: string[],
	defaultLanguage: string,
	isTemplate?: boolean
): ISurvey => {
	const reformated = reformatSurvey(values);
	const withParameters = setParameters(reformated.questions);
	const reformatAnalytics = reformatAnalyticsType(withParameters);
	const withRenamedAppearance = renameAppearanceValue(reformatAnalytics);
	const withDefaults = setDefaultQuestionValues(withRenamedAppearance);
	const withPositions = setQuestionPositions(withDefaults);
	const withRelevant = skipLogicToRelevant(withPositions);
	const withRemovedChoices = removeUnneccesaryChoices(withRelevant);
	const withMultiHints = coverMultiHints(
		withRemovedChoices,
		availableLanguages
	);
	const clone: any = Object.assign({}, values);
	delete clone.general_questions;
	delete clone.sections;
	return {
		...clone,
		questions: withMultiHints,
		survey_default_lang: defaultLanguage,
		survey_languages: availableLanguages,
		survey_description: coverMultiDescriptions(values, availableLanguages),
		isTemplate: isTemplate,
		survey_title: coverMultiTitels(values, availableLanguages),
		logo: values.logo === '' ? null : values.logo,
		return_url: values.return_url === '' ? null : values.return_url,
		tags: values.tags?.map(tag => tag.value)
	};
};
